let lastScroll = 0;

let scrollWindow = function () {

    $(window).scroll(function () {

        let $w = $(this),
            st = $w.scrollTop(),
            navbar = $('.ftco_navbar'),
            sd = $('.js-scroll-wrap');

        if (st > lastScroll) {

            if (st < 150) {
                navbar.addClass('awake');
                navbar.addClass('scrolled sleep');
            } else{
                navbar.removeClass('awake');
            }

        } else {

            if (st > 150) {
                if (!navbar.hasClass('scrolled')) {
                    navbar.addClass('scrolled');
                }
            }

            if (st < 150) {
                if (navbar.hasClass('scrolled')) {
                    navbar.removeClass('scrolled sleep');
                }
            }

            if (st > 350) {
                if (!navbar.hasClass('awake')) {
                    navbar.addClass('awake');
                }

                if (sd.length > 0) {
                    sd.addClass('sleep');
                }
            }

            if (st < 350) {
                if (navbar.hasClass('awake')) {
                    navbar.removeClass('awake');
                    navbar.addClass('sleep');
                }
                if (sd.length > 0) {
                    sd.removeClass('sleep');
                }
            }

        }

        lastScroll = st;
    });
};
scrollWindow();

$('nav .dropdown').hover(function () {

    let $this = $(this);

    // timer;
    // clearTimeout(timer);

    $this.addClass('show');

    $this.find('> a').attr('aria-expanded', true);
    $this.find('.dropdown-menu').addClass('animated-fast fadeInUp show');
    $this.find('.dropdown-menu').addClass('show');

}, function () {

    let $this = $(this);

    timer = setTimeout(function () {

        $this.find('> a').attr('aria-expanded', false);
        $this.find('.dropdown-menu').addClass('animated fadeInOut show');
        $this.find('.dropdown-menu').removeClass('show');
    }, 100);
});

function openlink(url) {

    let a = document.createElement("a");
    a.href = url;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}
