import ColorThief from "colorthief";

$('.carousel').carousel({
    interval: 6000
});

(function ($) {

    "use strict";

    $('.back-to-top').click(function() {
        $('html, body').animate({ scrollTop: 0 }, 400, "swing");
        return false;
    });


    if ($(this).scrollTop() != null) {
        $('.show-on-scroll').show();
    } else {
        $('.show-on-scroll').hide();
    }


    $(window).scroll(function () {

        // Muestra / Oculta la animación de Mouse Scroll
        if ($(this).scrollTop() > 0) {
            $('.hide-on-scroll').fadeOut();
        } else {
            $('.hide-on-scroll').fadeIn();
        }

        // Muestra / Oculta la animación de BackToTop
        if ($(this).scrollTop() > 20) {
            $('.show-on-scroll').fadeIn();
        } else {
            $('.show-on-scroll').fadeOut();
        }
    });


    $('[data-toggle="tooltip"]').tooltip()


    let fullHeight = function () {

        $('.js-fullheight').css('height', $(window).height());

        $(window).resize(function () {
            $('.js-fullheight').css('height', $(window).height());
        });
    };
    fullHeight();


    let minHeight = function () {

        $('.js-minheight').css('height', ($(window).height() * 35) / 100);

        $(window).resize(function () {
            $('.js-minheight').css('height', ($(window).height() * 35) / 100);
        });
    };
    minHeight();


    let quarterHeight = function () {

        $('.js-quarterheight').css('height', ($(window).height() * 50) / 100);

        $(window).resize(function () {
            $('.js-quarterheight').css('height', ($(window).height() * 50) / 100);
        });
    };
    quarterHeight();


    let mediumHeight = function () {

        $('.js-mediumheight').css('height', ($(window).height() * 60) / 100);

        $(window).resize(function () {
            $('.js-mediumheight').css('height', ($(window).height() * 60) / 100);
        });
    };
    mediumHeight();


    let loader = function () {
        setTimeout(function () {
            if ($('#ftco-loader').length > 0) {
                $('#ftco-loader').removeClass('show');
            }
        }, 1);
    };
    loader();

    let f_colorPromedio = function () {

        let colorThief = new ColorThief();
        let c_sourceImage = $('[id^="promedio-"]');

        c_sourceImage.each(function () {

            let target = this;
            let target_id = target.getAttribute('data-img-id');

            (function (sourceImage, img_id) {
                let _f_apply = function (_sourceImage, _img_id) {
                    let colorPromedio;
                    let colorSelec = 5;

                    colorPromedio = colorThief.getPalette(_sourceImage);
                    $('.slogan-container[data-img-id="' + _img_id + '"]').css('background-color', 'rgba(' + colorPromedio[colorSelec][0] + ',' + colorPromedio[colorSelec][1] + ',' + colorPromedio[colorSelec][2] + ', .35)');
                };

                if (sourceImage.complete) {
                    _f_apply(sourceImage, img_id);
                } else {
                    sourceImage.addEventListener('load', function () {
                        _f_apply(sourceImage, img_id);
                    });
                }
            })(target, target_id);
        })
    }
    f_colorPromedio();

})(jQuery);
